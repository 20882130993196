<script>
import AppBanner from "@/components/shared/AppBanner.vue";
import Develop from "../components/home/Develop.vue";
import DownlaodApp from "../components/home/DownlaodApp.vue";
import Features from "@/components/home/Features.vue";
// import Stories from "@/components/home/Stories.vue";
import AboutFarm from "@/components/home/AboutFarm.vue";
import AboutCounter from "@/components/home/AboutCounter.vue";
import WhatsIot4u from "@/components/home/WhatIot4u.vue";
import Background from "@/components/home/Background.vue";

export default {
  name: "Home",
  components: {
    AppBanner,
    Develop,
    DownlaodApp,
    Features,
    // Stories,
    AboutFarm,
    AboutCounter,
    Background,
    WhatsIot4u,
  },
};
</script>

<template>
  <div class="mx-auto">
    <!-- Banner -->
    <AppBanner class="mb-5 sm:mb-8" />

    <!-- Develop -->
    <Develop />

    <!-- Features -->
    <Features />

    <!-- Stories -->
    <!-- <Stories/> -->

    <!-- AboutFarm -->
    <AboutFarm />

    <WhatsIot4u />

    <!-- AboutCounter -->
    <AboutCounter />

    <!-- Background -->
    <Background />

    <!-- DownlaodApp -->
    <DownlaodApp />

    <!-- Load more projects button -->
  </div>
</template>

<style scoped></style>
