<script>
// import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
export default {
  props: ["showModal", "isOpen"],
  // components:{
  //   Popover,
  //   PopoverButton,
  //   PopoverGroup,
  //   PopoverPanel
  // },
  data() {
    return {
       solutions :[
  {
    name: 'About us',
    description: 'Learn about the IOTA4U team',
    href: '#',
    icon:<svg
                width="67"
                height="84"
                viewBox="0 0 67 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.4575 1.58008L2.06665 10.1503V75.0043L32.4575 82.4804V1.58008Z"
                  fill="#00E192"
                />
                <path
                  d="M50.3273 42.9724L49.4155 37.502L45.404 36.0433L41.0277 39.1431V44.2488L46.6804 46.2546L50.3273 42.9724Z"
                  fill="#00E192"
                />
                <path
                  d="M22.0328 78.4916L4.13123 8.95072L32.3607 1.37695V81.9342L22.0328 78.4916Z"
                  fill="#009F67"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34.3902 0.310376C34.7432 0.586881 34.9494 1.01037 34.9494 1.45877V82.5415C34.9494 82.9899 34.7432 83.4134 34.3902 83.6899C34.0372 83.9664 33.5767 84.0652 33.1413 83.9578L1.10939 76.0562C0.457838 75.8954 0 75.3109 0 74.6399V9.36038C0 8.68931 0.457838 8.1048 1.10939 7.94408L33.1413 0.0424685C33.5767 -0.064924 34.0372 0.0338711 34.3902 0.310376ZM2.91752 10.503V73.4972L32.0319 80.6791V3.3211L2.91752 10.503ZM44.0667 9.90742H37.7454V6.9899H45.5254C46.3311 6.9899 46.9842 7.64301 46.9842 8.44866V33.4299H44.0667V9.90742Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M44.0668 75.0046V49.2939H46.9843V75.0046H44.0668Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.4892 39.9336L28.2026 39.9336L28.2026 42.8511L22.4892 42.8511L22.4892 39.9336Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.936 37.1572C53.0984 35.8664 51.8968 34.853 50.4832 34.2451L51.6358 31.5649C53.5795 32.4008 55.2317 33.7942 56.3834 35.5691C57.5351 37.3439 58.1347 39.4204 58.1063 41.536C58.0779 43.6516 57.4227 45.7112 56.2237 47.4545C55.0248 49.1978 53.3358 50.5463 51.3703 51.3297L50.2902 48.6195C51.7196 48.0498 52.9479 47.069 53.8199 45.8012C54.6919 44.5334 55.1683 43.0354 55.189 41.4968C55.2097 39.9582 54.7736 38.448 53.936 37.1572Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.1716 34.9074C59.889 32.9309 58.0491 31.3791 55.8845 30.4483L57.0371 27.7681C59.7317 28.9269 62.0222 30.8587 63.619 33.3193C65.2157 35.7798 66.0469 38.6586 66.0075 41.5916C65.9681 44.5246 65.0599 47.38 63.3976 49.7968C61.7354 52.2136 59.3938 54.0832 56.6691 55.1692L55.5889 52.459C57.7776 51.5866 59.6586 50.0848 60.9938 48.1435C62.329 46.2021 63.0586 43.9084 63.0902 41.5524C63.1219 39.1964 62.4542 36.8839 61.1716 34.9074Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.7077 44.9179C47.6547 44.9179 49.2331 43.3395 49.2331 41.3926C49.2331 39.4456 47.6547 37.8672 45.7077 37.8672C43.7608 37.8672 42.1824 39.4456 42.1824 41.3926C42.1824 43.3395 43.7608 44.9179 45.7077 44.9179ZM45.7077 47.8354C49.266 47.8354 52.1506 44.9509 52.1506 41.3926C52.1506 37.8343 49.266 34.9497 45.7077 34.9497C42.1495 34.9497 39.2649 37.8343 39.2649 41.3926C39.2649 44.9509 42.1495 47.8354 45.7077 47.8354Z"
                  fill="#1E3540"
                />
              </svg>
  },
  {
    name: 'Partners',
    description: 'View our industry partners',
    href: '#',
    icon:<svg
                width="67"
                height="84"
                viewBox="0 0 67 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.4575 1.58008L2.06665 10.1503V75.0043L32.4575 82.4804V1.58008Z"
                  fill="#00E192"
                />
                <path
                  d="M50.3273 42.9724L49.4155 37.502L45.404 36.0433L41.0277 39.1431V44.2488L46.6804 46.2546L50.3273 42.9724Z"
                  fill="#00E192"
                />
                <path
                  d="M22.0328 78.4916L4.13123 8.95072L32.3607 1.37695V81.9342L22.0328 78.4916Z"
                  fill="#009F67"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34.3902 0.310376C34.7432 0.586881 34.9494 1.01037 34.9494 1.45877V82.5415C34.9494 82.9899 34.7432 83.4134 34.3902 83.6899C34.0372 83.9664 33.5767 84.0652 33.1413 83.9578L1.10939 76.0562C0.457838 75.8954 0 75.3109 0 74.6399V9.36038C0 8.68931 0.457838 8.1048 1.10939 7.94408L33.1413 0.0424685C33.5767 -0.064924 34.0372 0.0338711 34.3902 0.310376ZM2.91752 10.503V73.4972L32.0319 80.6791V3.3211L2.91752 10.503ZM44.0667 9.90742H37.7454V6.9899H45.5254C46.3311 6.9899 46.9842 7.64301 46.9842 8.44866V33.4299H44.0667V9.90742Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M44.0668 75.0046V49.2939H46.9843V75.0046H44.0668Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.4892 39.9336L28.2026 39.9336L28.2026 42.8511L22.4892 42.8511L22.4892 39.9336Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.936 37.1572C53.0984 35.8664 51.8968 34.853 50.4832 34.2451L51.6358 31.5649C53.5795 32.4008 55.2317 33.7942 56.3834 35.5691C57.5351 37.3439 58.1347 39.4204 58.1063 41.536C58.0779 43.6516 57.4227 45.7112 56.2237 47.4545C55.0248 49.1978 53.3358 50.5463 51.3703 51.3297L50.2902 48.6195C51.7196 48.0498 52.9479 47.069 53.8199 45.8012C54.6919 44.5334 55.1683 43.0354 55.189 41.4968C55.2097 39.9582 54.7736 38.448 53.936 37.1572Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.1716 34.9074C59.889 32.9309 58.0491 31.3791 55.8845 30.4483L57.0371 27.7681C59.7317 28.9269 62.0222 30.8587 63.619 33.3193C65.2157 35.7798 66.0469 38.6586 66.0075 41.5916C65.9681 44.5246 65.0599 47.38 63.3976 49.7968C61.7354 52.2136 59.3938 54.0832 56.6691 55.1692L55.5889 52.459C57.7776 51.5866 59.6586 50.0848 60.9938 48.1435C62.329 46.2021 63.0586 43.9084 63.0902 41.5524C63.1219 39.1964 62.4542 36.8839 61.1716 34.9074Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.7077 44.9179C47.6547 44.9179 49.2331 43.3395 49.2331 41.3926C49.2331 39.4456 47.6547 37.8672 45.7077 37.8672C43.7608 37.8672 42.1824 39.4456 42.1824 41.3926C42.1824 43.3395 43.7608 44.9179 45.7077 44.9179ZM45.7077 47.8354C49.266 47.8354 52.1506 44.9509 52.1506 41.3926C52.1506 37.8343 49.266 34.9497 45.7077 34.9497C42.1495 34.9497 39.2649 37.8343 39.2649 41.3926C39.2649 44.9509 42.1495 47.8354 45.7077 47.8354Z"
                  fill="#1E3540"
                />
              </svg>
  },
  { name: 'Stories', description: "Read about our latest customer projects", href: '#', icon:<svg
                width="67"
                height="84"
                viewBox="0 0 67 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.4575 1.58008L2.06665 10.1503V75.0043L32.4575 82.4804V1.58008Z"
                  fill="#00E192"
                />
                <path
                  d="M50.3273 42.9724L49.4155 37.502L45.404 36.0433L41.0277 39.1431V44.2488L46.6804 46.2546L50.3273 42.9724Z"
                  fill="#00E192"
                />
                <path
                  d="M22.0328 78.4916L4.13123 8.95072L32.3607 1.37695V81.9342L22.0328 78.4916Z"
                  fill="#009F67"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34.3902 0.310376C34.7432 0.586881 34.9494 1.01037 34.9494 1.45877V82.5415C34.9494 82.9899 34.7432 83.4134 34.3902 83.6899C34.0372 83.9664 33.5767 84.0652 33.1413 83.9578L1.10939 76.0562C0.457838 75.8954 0 75.3109 0 74.6399V9.36038C0 8.68931 0.457838 8.1048 1.10939 7.94408L33.1413 0.0424685C33.5767 -0.064924 34.0372 0.0338711 34.3902 0.310376ZM2.91752 10.503V73.4972L32.0319 80.6791V3.3211L2.91752 10.503ZM44.0667 9.90742H37.7454V6.9899H45.5254C46.3311 6.9899 46.9842 7.64301 46.9842 8.44866V33.4299H44.0667V9.90742Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M44.0668 75.0046V49.2939H46.9843V75.0046H44.0668Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.4892 39.9336L28.2026 39.9336L28.2026 42.8511L22.4892 42.8511L22.4892 39.9336Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.936 37.1572C53.0984 35.8664 51.8968 34.853 50.4832 34.2451L51.6358 31.5649C53.5795 32.4008 55.2317 33.7942 56.3834 35.5691C57.5351 37.3439 58.1347 39.4204 58.1063 41.536C58.0779 43.6516 57.4227 45.7112 56.2237 47.4545C55.0248 49.1978 53.3358 50.5463 51.3703 51.3297L50.2902 48.6195C51.7196 48.0498 52.9479 47.069 53.8199 45.8012C54.6919 44.5334 55.1683 43.0354 55.189 41.4968C55.2097 39.9582 54.7736 38.448 53.936 37.1572Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.1716 34.9074C59.889 32.9309 58.0491 31.3791 55.8845 30.4483L57.0371 27.7681C59.7317 28.9269 62.0222 30.8587 63.619 33.3193C65.2157 35.7798 66.0469 38.6586 66.0075 41.5916C65.9681 44.5246 65.0599 47.38 63.3976 49.7968C61.7354 52.2136 59.3938 54.0832 56.6691 55.1692L55.5889 52.459C57.7776 51.5866 59.6586 50.0848 60.9938 48.1435C62.329 46.2021 63.0586 43.9084 63.0902 41.5524C63.1219 39.1964 62.4542 36.8839 61.1716 34.9074Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.7077 44.9179C47.6547 44.9179 49.2331 43.3395 49.2331 41.3926C49.2331 39.4456 47.6547 37.8672 45.7077 37.8672C43.7608 37.8672 42.1824 39.4456 42.1824 41.3926C42.1824 43.3395 43.7608 44.9179 45.7077 44.9179ZM45.7077 47.8354C49.266 47.8354 52.1506 44.9509 52.1506 41.3926C52.1506 37.8343 49.266 34.9497 45.7077 34.9497C42.1495 34.9497 39.2649 37.8343 39.2649 41.3926C39.2649 44.9509 42.1495 47.8354 45.7077 47.8354Z"
                  fill="#1E3540"
                />
              </svg> },
  {
    name: 'Blog',
    description: "Discover agriculture and tech trends",
    href: '#',
    icon:<svg
                width="67"
                height="84"
                viewBox="0 0 67 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.4575 1.58008L2.06665 10.1503V75.0043L32.4575 82.4804V1.58008Z"
                  fill="#00E192"
                />
                <path
                  d="M50.3273 42.9724L49.4155 37.502L45.404 36.0433L41.0277 39.1431V44.2488L46.6804 46.2546L50.3273 42.9724Z"
                  fill="#00E192"
                />
                <path
                  d="M22.0328 78.4916L4.13123 8.95072L32.3607 1.37695V81.9342L22.0328 78.4916Z"
                  fill="#009F67"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34.3902 0.310376C34.7432 0.586881 34.9494 1.01037 34.9494 1.45877V82.5415C34.9494 82.9899 34.7432 83.4134 34.3902 83.6899C34.0372 83.9664 33.5767 84.0652 33.1413 83.9578L1.10939 76.0562C0.457838 75.8954 0 75.3109 0 74.6399V9.36038C0 8.68931 0.457838 8.1048 1.10939 7.94408L33.1413 0.0424685C33.5767 -0.064924 34.0372 0.0338711 34.3902 0.310376ZM2.91752 10.503V73.4972L32.0319 80.6791V3.3211L2.91752 10.503ZM44.0667 9.90742H37.7454V6.9899H45.5254C46.3311 6.9899 46.9842 7.64301 46.9842 8.44866V33.4299H44.0667V9.90742Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M44.0668 75.0046V49.2939H46.9843V75.0046H44.0668Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.4892 39.9336L28.2026 39.9336L28.2026 42.8511L22.4892 42.8511L22.4892 39.9336Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.936 37.1572C53.0984 35.8664 51.8968 34.853 50.4832 34.2451L51.6358 31.5649C53.5795 32.4008 55.2317 33.7942 56.3834 35.5691C57.5351 37.3439 58.1347 39.4204 58.1063 41.536C58.0779 43.6516 57.4227 45.7112 56.2237 47.4545C55.0248 49.1978 53.3358 50.5463 51.3703 51.3297L50.2902 48.6195C51.7196 48.0498 52.9479 47.069 53.8199 45.8012C54.6919 44.5334 55.1683 43.0354 55.189 41.4968C55.2097 39.9582 54.7736 38.448 53.936 37.1572Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.1716 34.9074C59.889 32.9309 58.0491 31.3791 55.8845 30.4483L57.0371 27.7681C59.7317 28.9269 62.0222 30.8587 63.619 33.3193C65.2157 35.7798 66.0469 38.6586 66.0075 41.5916C65.9681 44.5246 65.0599 47.38 63.3976 49.7968C61.7354 52.2136 59.3938 54.0832 56.6691 55.1692L55.5889 52.459C57.7776 51.5866 59.6586 50.0848 60.9938 48.1435C62.329 46.2021 63.0586 43.9084 63.0902 41.5524C63.1219 39.1964 62.4542 36.8839 61.1716 34.9074Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.7077 44.9179C47.6547 44.9179 49.2331 43.3395 49.2331 41.3926C49.2331 39.4456 47.6547 37.8672 45.7077 37.8672C43.7608 37.8672 42.1824 39.4456 42.1824 41.3926C42.1824 43.3395 43.7608 44.9179 45.7077 44.9179ZM45.7077 47.8354C49.266 47.8354 52.1506 44.9509 52.1506 41.3926C52.1506 37.8343 49.266 34.9497 45.7077 34.9497C42.1495 34.9497 39.2649 37.8343 39.2649 41.3926C39.2649 44.9509 42.1495 47.8354 45.7077 47.8354Z"
                  fill="#1E3540"
                />
              </svg>
  },
  {
    name: 'Help guides',
    description: 'Helping you navigate the Farmdeck platform',
    href: '#',
    icon:<svg
                width="67"
                height="84"
                viewBox="0 0 67 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.4575 1.58008L2.06665 10.1503V75.0043L32.4575 82.4804V1.58008Z"
                  fill="#00E192"
                />
                <path
                  d="M50.3273 42.9724L49.4155 37.502L45.404 36.0433L41.0277 39.1431V44.2488L46.6804 46.2546L50.3273 42.9724Z"
                  fill="#00E192"
                />
                <path
                  d="M22.0328 78.4916L4.13123 8.95072L32.3607 1.37695V81.9342L22.0328 78.4916Z"
                  fill="#009F67"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M34.3902 0.310376C34.7432 0.586881 34.9494 1.01037 34.9494 1.45877V82.5415C34.9494 82.9899 34.7432 83.4134 34.3902 83.6899C34.0372 83.9664 33.5767 84.0652 33.1413 83.9578L1.10939 76.0562C0.457838 75.8954 0 75.3109 0 74.6399V9.36038C0 8.68931 0.457838 8.1048 1.10939 7.94408L33.1413 0.0424685C33.5767 -0.064924 34.0372 0.0338711 34.3902 0.310376ZM2.91752 10.503V73.4972L32.0319 80.6791V3.3211L2.91752 10.503ZM44.0667 9.90742H37.7454V6.9899H45.5254C46.3311 6.9899 46.9842 7.64301 46.9842 8.44866V33.4299H44.0667V9.90742Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M44.0668 75.0046V49.2939H46.9843V75.0046H44.0668Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.4892 39.9336L28.2026 39.9336L28.2026 42.8511L22.4892 42.8511L22.4892 39.9336Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.936 37.1572C53.0984 35.8664 51.8968 34.853 50.4832 34.2451L51.6358 31.5649C53.5795 32.4008 55.2317 33.7942 56.3834 35.5691C57.5351 37.3439 58.1347 39.4204 58.1063 41.536C58.0779 43.6516 57.4227 45.7112 56.2237 47.4545C55.0248 49.1978 53.3358 50.5463 51.3703 51.3297L50.2902 48.6195C51.7196 48.0498 52.9479 47.069 53.8199 45.8012C54.6919 44.5334 55.1683 43.0354 55.189 41.4968C55.2097 39.9582 54.7736 38.448 53.936 37.1572Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.1716 34.9074C59.889 32.9309 58.0491 31.3791 55.8845 30.4483L57.0371 27.7681C59.7317 28.9269 62.0222 30.8587 63.619 33.3193C65.2157 35.7798 66.0469 38.6586 66.0075 41.5916C65.9681 44.5246 65.0599 47.38 63.3976 49.7968C61.7354 52.2136 59.3938 54.0832 56.6691 55.1692L55.5889 52.459C57.7776 51.5866 59.6586 50.0848 60.9938 48.1435C62.329 46.2021 63.0586 43.9084 63.0902 41.5524C63.1219 39.1964 62.4542 36.8839 61.1716 34.9074Z"
                  fill="#1E3540"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.7077 44.9179C47.6547 44.9179 49.2331 43.3395 49.2331 41.3926C49.2331 39.4456 47.6547 37.8672 45.7077 37.8672C43.7608 37.8672 42.1824 39.4456 42.1824 41.3926C42.1824 43.3395 43.7608 44.9179 45.7077 44.9179ZM45.7077 47.8354C49.266 47.8354 52.1506 44.9509 52.1506 41.3926C52.1506 37.8343 49.266 34.9497 45.7077 34.9497C42.1495 34.9497 39.2649 37.8343 39.2649 41.3926C39.2649 44.9509 42.1495 47.8354 45.7077 47.8354Z"
                  fill="#1E3540"
                />
              </svg>

  },
],
      show: false,
    };
  },
};
</script>

<template>
  <!-- Header links -->
  <div
    :class="isOpen ? 'block' : 'hidden'"
    class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
  >
    <router-link
      to="/products"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-500 dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2"
      aria-label="Projects"
      >Our Products</router-link
    >
    <router-link
      to="/prices"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-500 dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="About Me"
      >Pricing</router-link
    >
     <router-link
      to="/contact"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-500 dark:hover:text-indigo-300 sm:mx-4 mb-1  sm:py-2 border-t-2 pt-1 sm:pt-1 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >Contact us</router-link
    >
      <router-link
      to="/coverage"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-500 dark:hover:text-indigo-300 sm:mx-4 mb-1  sm:py-2 border-t-2 pt-1 sm:pt-1 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >Coverage Checker</router-link
    >
     <router-link
      to="/features"
      class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-500 dark:hover:text-indigo-300 sm:mx-4 mb-1  sm:py-2 border-t-2 pt-1 sm:pt-1 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >Features</router-link
    >
   
    <!-- <div class="relative">
      <PopoverGroup as="nav" class="space-x-5">
          <Popover class="relative" v-slot="{ open }">
            <PopoverButton :class="[open ? 'text-primary-900' : 'text-primary-500', 'flex justify-between items-center w-full font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-500 dark:hover:text-indigo-300 sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark']">
              <span>Solutions</span>
              <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-start rounded-lg hover:bg-green-50">
                      <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      
                      <div class="ml-4">
                        <p class="flex text-xl text-gray-900 justify-start">
                          {{ item.name }}
                        </p>
                        <p class="flex text-sm text-gray-500 justify-center">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </PopoverGroup>
    </div> -->
    

  </div>
</template>

<style lang="scss" scoped></style>
