<script>
export default {
  data: () => {
    return {
      copyrightDate: new Date().getFullYear(),
      projectName: "IOTA4U",
      author: "IOTA4U",
    };
  },
};
</script>

<template>
  <div>
    <div class="flex justify-left items-left text-left">
      <div class="font-general-regular text-m text-sky-300 dark:text-sky-300">
        &copy; {{ copyrightDate }}.
        <a
          href=""
          target="__blank"
          class="font-general-medium hover:underline hover:text-sky-300 dark:hover:text-sky-300 duration-500"
        >
          {{ projectName }}
        </a>
        . Developed by
        <a
          href=""
          target="__blank"
          class="font-general-medium text-sky-300 dark:text-sky-300 uppercase hover:underline hover:text-sky-300 dark:hover:text-sky-300 duration-500"
          >{{ author }}</a
        >
      </div>
    </div>
    <div class="justify-center items-center text-center mt-1">
      <div class="font-general-regular text-m text-sky-300 dark:text-sky-300">
        <a
          href="/privacy&policy"
          class="font-general-medium hover:underline hover:text-sky-300 dark:hover:text-sky-300 duration-500"
        >
          <p>Terms and conditions | Privacy policy</p>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
