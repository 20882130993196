<template>
  <div class="container m-auto px-6 md:px-12 xl:px-0 mt-10 sm:mt-20">
    <div class="flex flex-col mb-20 animated fadeIn sm:flex-row">
      <div
        class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-2/2 md:w-12/12 sm:pr-16"
      >
        <h4 class="mt-2 text-xl sm:text-left md:text-xl font-bold underline">
          Background
        </h4>
        <a class="mt-5 text-lg text-gray-700 text md:text-left">
          Inadequate or Over water supply is one of the biggest challenges
          facing the agricultural industry WORLDWIDE. In 2016 Australia had
          around 25 million head of cattle, of which more than 30% were grazed
          in areas with low and unreliable rainfall.
        </a>
        <h4 class="mt-2 text-xl sm:text-left md:text-xl font-bold underline">
          Traditional Water Tank Monitoring
        </h4>
        <a class="mt-5 text-lg text-gray-700 text md:text-left">
          Traditional manual systems involve a simple water float with a
          stainless-steel shaft attached which served at the time to be
          economical and effective at a basic level which required manual
          inspection and more often than not mechanical issues , the logic was
          simple that if the visible 'ball' is high, the tank is full if its
          level with the top of the tank the tank is empty. It did not provide
          any certainty of Water Tank Leakage or overflow issues, in short of
          keeping a physical notebook of tank levels, adding to your other
          issues .
        </a>

        <h4 class="mt-2 text-xl sm:text-left md:text-xl font-bold underline">
          IOTA Wireless Monitoring
        </h4>
        <a class="mt-5 text-lg text-gray-700 text md:text-left">
          Simplifies the manual and mental stress of keeping an eye out for your
          Tank Levels, where Tank levels can now be monitored by the palms of
          your hand , our Application , downloadable for Android and iPhone
          devices provides current Tank levels and sends notifications to your
          phone , when Water Levels change at any instance , giving you time to
          respond…”.better late than Never!“
        </a>
      </div>
    </div>
  </div>
</template>
