<template>
  <div>
    <dh-component>
      <section class="max-w-8xl mx-auto container bg-white pt-1">
        <div>
          <div role="contentinfo" class="flex items-center flex-col px-4">
            <h3
              tabindex="0"
              class="focus:outline-none text-3xl lg:text-3xl font-extrabold text-center leading-10 text-gray-800 lg:w-5/12 md:w-9/12 pt-4"
            >
              WHAT IS IOTA4U
            </h3>
            <p
              tabindex="0"
              class="focus:outline-none uppercase text-md text-center text-gray-600 leading-4"
            >
              Internet Of Things Agriculture
            </p>
          </div>
          <div
            tabindex="0"
            aria-label="group of cards"
            class="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4"
          >
            <div
              tabindex="0"
              aria-label="card 1"
              class="focus:outline-none flex sm:w-full md:w-5/12 pb-10"
            >
              <div class="w-12/12">
                <h2
                  tabindex="0"
                  class="focus:outline-none text-lg font-bold leading-tight text-gray-800"
                >
                  Innovative smart sensors
                </h2>
                <p
                  tabindex="0"
                  class="focus:outline-none text-sm text-gray-600 leading-normal pt-2"
                >
                  Continuous monitoring and notifications of devices around your
                  farm, giving you piece of mind.
                </p>
              </div>
            </div>
            <div
              tabindex="0"
              aria-label="card 2"
              class="focus:outline-none flex sm:w-full md:w-5/12 pb-10"
            >
              <div class="w-12/12">
                <h2
                  tabindex="0"
                  class="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                >
                  Always connected
                </h2>
                <p
                  tabindex="0"
                  class="focus:outline-none text-sm text-gray-600 leading-normal pt-2"
                >
                  Devices work only wherever Sigfox Wireless Coverage is
                  available. View your assets anytime when your sensor detects
                  change, and from anywhere.
                </p>
              </div>
            </div>
            <div
              tabindex="0"
              aria-label="card 3"
              class="focus:outline-none flex sm:w-full md:w-5/12 pb-10"
            >
              <div class="w-12/12">
                <h2
                  tabindex="0"
                  class="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                >
                  Easy to install and Set up
                </h2>
                <p
                  tabindex="0"
                  class="focus:outline-none text-sm text-gray-600 leading-normal pt-2"
                >
                  IOTA hardware is all powered by 2 x AA batteries, and can be
                  self-installed around your property in under 30 minutes.
                </p>
              </div>
            </div>
            <div
              tabindex="0"
              aria-label="card 4"
              class="focus:outline-none flex sm:w-full md:w-5/12 pb-10"
            >
              <div class="w-12/12">
                <h2
                  tabindex="0"
                  class="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                >
                  IOTA4U App
                </h2>
                <p
                  tabindex="0"
                  class="focus:outline-none text-sm text-gray-600 leading-normal pt-2"
                >
                  Our mobile app alerts for min & max water levels, opened and
                  closed gates, and temperature conditions. Get notified on both
                  IOS and Android.
                </p>
              </div>
            </div>

              <div
              tabindex="0"
              aria-label="card 3"
              class="focus:outline-none flex sm:w-full md:w-6/12 pb-10"
            >
              <div class="w-12/12">
                <h2
                  tabindex="0"
                  class="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                >
                   A solution designed to ensure your Farm assets are Safe
                </h2>
                <p
                  tabindex="0"
                  class="focus:outline-none text-gray-600 leading-normal pt-2 text-sm"
                >
                  IOTA4U The Smart Farm Analyser, are devices that monitor
                  your Vital Water Tank Levels, Remote Gates Opening and
                  Closing, Flood Levels, and Temperature/Humidity conditions.
                  IoTA4U combined with the Smart Phone App will notify you with
                  Alerts when and ONLY WHEN you need it MOST.
                </p>
              </div>
            </div>
            <div
              tabindex="0"
              aria-label="card 3"
              class="focus:outline-none flex sm:w-full md:w-4/12 pb-10"
            >
              <div class="w-12/12">
                <h2
                  tabindex="0"
                  class="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                >
                 Just a few of the advantages with using IoT
                </h2>
                 <p class="flex items-center -mx-1 text-gray-600 text-sm">
              <span class="mx-2 text-center">1. Automate tasks</span>
            </p>

            <p class="flex items-center -mx-1 text-gray-600 text-sm">
              <span class="mx-2 text-center">2. Monitor &amp;Receive alerts when specific events occur</span>
            </p>
            <p class="flex items-center -mx-1 text-gray-600 text-sm">
              <span class="mx-2 text-center">3. Save money</span>
            </p>
             <p class="flex items-center -mx-1 text-gray-600 text-sm">
              <span class="mx-2 text-center">4. Save time</span>
            </p>
              <p class="flex items-center -mx-1 text-gray-600 text-sm">
              <span class="mx-2 text-center">5. Better assignment of your and your staff time</span>
            </p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </dh-component>
  </div>
</template>
