<script>
import Button from "../reusable/Button.vue";
export default {
    components:{
        Button
    }
}
</script>


<template>
  <div class="bg-white dark:bg-gray-900">
    <div class="flex justify-center h-screen">
      <div
        class="hidden bg-cover lg:block lg:w-2/4 bg-burger"
      
      >
        <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
          <div>
            <h2 class="text-4xl font-bold text-white">Water Level Management</h2>

            <p class="max-w-xl mt-3 text-gray-300">
          Monitor your water levels in real-time from anywhere, including water tanks, dams, troughs, and turkey nests.
            </p>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1">
          <div class="text-center  px-6 mx-auto lg:w-3/6">
            <div>
              <router-link to="/"
                ><img
                  src="@/assets/images/IOTA4ULogo.png"
                  class="w-36"
                  alt="Dark Logo"
                />
              </router-link>
            </div>
          </div>
             <h4
          class="w-full text-3xl font-bold text-center sm:text-2xl md:text-2xl"
        >
       Welcome back!
        </h4>
         <h6
          class="w-full text-1xl text-center sm:text-1xl md:text-1xl"
        >
       Please login to your IOTA4U account.
        </h6>

          <div class="mt-8">
            <form>
               <div class="mt-6">
                <div class="flex justify-between mb-2">
                  <label
                    for="email"
                    class="text-sm text-gray-600 dark:text-gray-200"
                    >Email</label
                  >
                </div>

                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-emerald-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-emerald-700 focus:border-emerald-400 dark:focus:border-emerald-400 focus:ring-emerald-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div class="mt-6">
                <div class="flex justify-between mb-2">
                  <label
                    for="password"
                    class="text-sm text-gray-600 dark:text-gray-200"
                    >Password</label
                  >
                  <a
                    href="#"
                    class="text-sm text-gray-400 focus:text-emerald-500 hover:text-emerald-500 hover:underline"
                    >Forgot password?</a
                  >
                </div>

                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Your Password"
                  class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-emerald-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-emerald-400 dark:focus:border-emerald-400 focus:ring-emerald-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div class="mt-6">
                 <router-link
            to="/"
            aria-label="More Projects"
          >
            <Button
              title="Login"
               class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-emerald-500 rounded-md hover:bg-emerald-100  focus:outline-none focus:bg-emerald-500  focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              aria-label="Hire Me Button"
            />
          </router-link>
              </div>
            </form>

            <p class="mt-6 text-sm text-center text-gray-400">
              Don&#x27;t have an account yet?
              <a
                href="#"
                class="text-emerald-500 focus:outline-none focus:underline hover:underline"
                >Sign up</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-burger {
  background-image: url("@/assets/images/feature/png/featurehome.png");
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
</style>


