<template>
  <div
    class="p-4 w-full text-center bg-white sm:p-8 dark:bg-gray-800 dark:border-gray-700"
  >
    <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">
      Bring IOTA4U to mobile and tablet, too
    </h5>
    <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">
      Managing your farm on your phone has never been easier!
    </p>
    <div
      class="justify-center items-center space-y-2 sm:flex sm:space-y-0 sm:space-x-2"
    >
      <!-- <a href="https://apps.apple.com/se/app/iota4u/id1634092295?l=en" target="__blank" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <svg class="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path></svg>
            <div class="text-left">
                <div class="mb-1 text-xs">Download on the</div>
                <div class="-mt-1 font-sans text-sm font-semibold">Mac App Store</div>
            </div>
        </a> -->
      <a
        href="https://apps.apple.com/se/app/iota4u/id1634092295?l=en"
        target="__blank"
        class="w-full sm:w-auto bg-white hover:bg-whitefocus:ring-4 focus:outline-none focus:ring-white text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-white dark:hover:bg-white dark:focus:ring-white"
      >
        <div
          class="flex mt-0 w-48 h-14 bg-black text-white rounded-xl items-center justify-center"
        >
          <div class="mr-3">
            <svg viewBox="0 0 384 512" width="30" >
                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                </svg>
          </div>
          <div>
            <div class="text-xs">Download on the</div>
            <div class="text-2xl font-semibold font-sans -mt-1">App Store</div>
          </div>
        </div>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.iota4you.iota4u"
        target="__blank"
        class="w-full sm:w-auto bg-white hover:bg-whitefocus:ring-4 focus:outline-none focus:ring-white text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-white dark:hover:bg-white dark:focus:ring-white"
      >
        <div
          class="flex mt-0 w-48 h-14 bg-black text-white rounded-xl items-center justify-center"
        >
          <div class="mr-1">
            <svg
              class="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              width="436.057"
              height="469.847"
              viewBox="0 0 436.057 469.847"
            >
              <g transform="translate(-16.896)">
                <path
                  d="M270.336,234.965,34.39,462.165A40.146,40.146,0,0,1,16.9,428.672V41.258A40.146,40.146,0,0,1,34.39,7.765Z"
                  fill="#2196f3"
                />
                <path
                  d="M352.9,155.6l-82.56,79.36L34.39,7.765a31.446,31.446,0,0,1,2.773-1.92A40.363,40.363,0,0,1,77.91,5.2Z"
                  fill="#4caf50"
                />
                <path
                  d="M452.95,234.965a40.791,40.791,0,0,1-21.333,36.267L352.9,314.325l-82.56-79.36L352.9,155.6l78.72,43.093A40.791,40.791,0,0,1,452.95,234.965Z"
                  fill="#ffc107"
                />
                <path
                  d="M352.9,314.325,77.91,464.725a40.9,40.9,0,0,1-40.747-.64,31.44,31.44,0,0,1-2.773-1.92l235.947-227.2Z"
                  fill="#f44336"
                />
              </g>
            </svg>
          </div>
          <div>
            <div class="text-xs">Download on the</div>
            <div class="text-2xl font-semibold font-sans -mt-1">Play Store</div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
