<template>
  <div class="container mx-auto">
    <header class="text-center mx-auto mb-12 lg:px-20">
       <h2 class="text-2xl leading-normal mb-10 mt-3 font-bold text-black">
            What other farmers say about IOTA4U
        </h2>
    </header>
    <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
      <div
        class="flex flex-col justify-center mt-2 mb-2 md:mt-0 sm:w-1/3 md:w-6/12 sm:pr-16"
      >
        <a href="#" class="flex flex-col items-center md:flex-row md:max-w-xl">
          <img
            class="mb-12 w-20 h-20 rounded-full shadow-lg"
            src="@/assets/images/male.png"
            alt="Bonnie image"
          />
          <div class="flex flex-col justify-between p-4 leading-normal">
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
             I lost my hens due to flooding 3 years, the IoTA Team provided a Flood Detector in my  coop, the notifications worked a charm, when I received a notification  , it gave me time to respond and move my hens.
            </p>
            <h6
              class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
            >
              Mohammad Ahmad  
            </h6>
          </div>
        </a>
      </div>
      <div class="flex items-center mb-2 sm:w-1/3 md:w-5/12 sm:order-last">
        <a href="#" class="flex flex-col items-center md:flex-row md:max-w-xl">
          <img
            class="mb-10 w-20 h-20 rounded-full shadow-lg"
            src="@/assets/images/female.jpg"
            alt="Bonnie image"
          />
          <div class="flex flex-col justify-between p-4 leading-normal">
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              We have a Water Tank supplying our water troughs for our Cattle. The IoTA device was my saving grace, as the Water Level Alert 
              function gave me enough time to visit the though, where my cattle feed from to herd them to another trough.
            </p>
            <h6
              class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
            >
              Ben root
            </h6>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
