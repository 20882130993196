<script>
import feather from "feather-icons";

export default {
  name: "Home",
  data: () => {
    return {
      theme: "",
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div class="bg-burger bg-top pt-12 pb-32 banner">
    <div class="w-full md:w-3/3 text-center">
      <h1
        class="font-general-semibold text-3xl mt-40 md:text-3xl xl:text-3xl text-center sm:text-center text-white dark:text-primary-light uppercase"
      >
      Internet of Things Agriculture
      </h1>
      <div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-burger {
  background-image: url("../../assets/images/MainBanner.png");
}

.triangle-menu {
  position: relative;
}

.triangle-menu::after,
.triangle-menu::before {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 16px;
  position: absolute;
}

.triangle-menu::after {
  left: 100%;
  border-color: transparent transparent transparent #ef4444;
}

.triangle-menu::before {
  right: 100%;
  border-color: transparent #ef4444 transparent transparent;
}
.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
</style>
