<script>
// import Button from "@/components/reusable/Button.vue";
export default {
  components: {
    // Button,
  },
};
</script>
<template>
  <!-- component -->
  <div
    id="services"
    class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white bg-gray-100 border-b border-gray-100"
  >
    <div class="xl:max-w-6xl mx-auto px-4">
      <!-- Heading start -->
      <header class="text-center mx-auto mb-12 lg:px-20">
        <h2 class="text-2xl leading-normal mb-2 font-bold text-black">
          Our Products
        </h2>

        <p
          class="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2"
        >
          Managing your farm is much simpler because to our wide variety of
          products.
        </p>
        <!-- <div class="mt-10 sm:mt-2 flex justify-center">
          <router-link
            to="/features"
            class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-emerald-500 hover:bg-emerald-100 focus:ring-1 focus:ring-emerald-900 text-white text-lg sm:text-xl duration-300"
            aria-label="More Projects"
          >
            <Button title="See All Products" />
          </router-link>
        </div> -->
      </header>

      <div class="flex flex-col m-auto p-auto">
        <div class="flex overflow-x-scroll pb-10">
          <div class="flex flex-nowrap lg:ml-10 md:ml-10 ml-1">
            <div
              class="inline-block px-4 cursor-pointer"
              @click="$router.push('/product/temperature-wireless-sensor')"
            >
              <div
                class="w-96 h-64 max-w-xs overflow-hidden rounded-lg shadow-md hover:shadow-xl bg-white transition-shadow duration-300 ease-in-out"
              >
                <div class="inline-block text-gray-900 mt-4">
                  <svg
                    width="30"
                    height="50"
                    viewBox="0 0 46 84"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M31.3624 45.3855V10.0731C31.3624 6.01007 28.0579 2.71634 23.9816 2.71634C19.9054 2.71634 16.6009 6.01007 16.6009 10.0731V45.3855V49.1235V49.1703V51.9892V52.1599C11.8615 54.7734 8.65244 59.8074 8.65244 65.5883C8.65244 74.0582 15.541 80.9243 24.0384 80.9243C32.5359 80.9243 39.4244 74.0582 39.4244 65.5883C39.4244 59.7613 36.164 54.6933 31.3624 52.0979V52.0141V49.2019V49.0729V45.3855ZM34.0876 50.5675V10.0731C34.0876 4.50988 29.563 0 23.9816 0C18.4003 0 13.8757 4.50988 13.8757 10.0731V50.6437C9.07867 53.891 5.92725 59.3728 5.92725 65.5883C5.92725 75.5584 14.0359 83.6407 24.0384 83.6407C34.0409 83.6407 42.1496 75.5584 42.1496 65.5883C42.1496 59.3239 38.9484 53.8048 34.0876 50.5675Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M21.2613 19.8296V55.9674C19.0039 56.7567 14.4889 59.6883 14.4889 65.1005C14.4889 71.8658 21.2613 75.0793 24.8994 75.0793C28.5375 75.0793 34.2465 71.9222 34.2465 65.1005C34.2465 59.6432 29.7316 56.7379 27.4741 55.9674V19.8296C27.4741 18.1947 25.9629 17.1235 24.0039 17.1235C22.4367 17.1235 21.5225 18.9276 21.2613 19.8296Z"
                      fill="#00E192"
                    />
                    <path
                      d="M24.3678 59.6022C30.1633 66.4515 25.0264 72.7739 21.7334 75.079H27.6607L32.2708 70.1396L34.5759 65.2002L32.6001 59.9315L27.3314 55.6506V43.7961V27.0021V19.099L24.6971 16.7939C22.8311 17.4525 19.6259 18.7697 21.7334 18.7697C23.8409 18.7697 24.3678 20.5259 24.3678 21.4041V59.6022Z"
                      fill="#009F67"
                    />
                    <path
                      d="M44.1083 11.8545C44.8452 11.8545 45.4426 12.4442 45.4426 13.1717C45.4426 13.8991 44.8452 14.4888 44.1083 14.4888L38.2153 14.4888C37.4784 14.4888 36.881 13.8991 36.881 13.1717C36.881 12.4442 37.4784 11.8545 38.2153 11.8545L44.1083 11.8545Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M44.1083 21.7334C44.8452 21.7334 45.4426 22.3231 45.4426 23.0506C45.4426 23.778 44.8452 24.3678 44.1083 24.3678L38.2153 24.3678C37.4784 24.3678 36.881 23.778 36.881 23.0506C36.881 22.3231 37.4784 21.7334 38.2153 21.7334L44.1083 21.7334Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M44.1083 31.6123C44.8452 31.6123 45.4426 32.3495 45.4426 33.2588C45.4426 34.1681 44.8452 34.9052 44.1083 34.9052L38.2153 34.9052C37.4784 34.9052 36.881 34.1681 36.881 33.2588C36.881 32.3495 37.4784 31.6123 38.2153 31.6123L44.1083 31.6123Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M44.1083 42.1494C44.8452 42.1494 45.4426 42.7391 45.4426 43.4666C45.4426 44.194 44.8452 44.7838 44.1083 44.7838L38.2153 44.7838C37.4784 44.7838 36.881 44.194 36.881 43.4666C36.881 42.7391 37.4784 42.1494 38.2153 42.1494L44.1083 42.1494Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.658569 24.3677H9.8788L5.32704 11.8545L0.658569 24.3677Z"
                      fill="#00E192"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.6591 10.5376C6.13632 10.5395 6.56097 10.8364 6.72044 11.2797L11.131 23.5396C11.2537 23.8807 11.2007 24.2594 10.9888 24.5551C10.7768 24.8507 10.4324 25.0265 10.0651 25.0265H1.13091C0.761998 25.0265 0.416271 24.8492 0.204625 24.5514C-0.00702125 24.2536 -0.0579795 23.8728 0.0680951 23.5311L4.59172 11.2712C4.75481 10.8292 5.18188 10.5357 5.6591 10.5376ZM2.74551 22.7975H8.46424L5.64107 14.95L2.74551 22.7975Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M9.87891 31.6123L0.658674 31.6123L5.21043 44.1255L9.87891 31.6123Z"
                      fill="#00E192"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.53695 44.7842C5.05973 44.7823 4.63507 44.4854 4.4756 44.0421L0.0650656 31.7822C-0.0576751 31.441 -0.00464033 31.0624 0.207293 30.7667C0.419226 30.471 0.763655 30.2952 1.13096 30.2952L10.0651 30.2952C10.434 30.2952 10.7798 30.4726 10.9914 30.7704C11.2031 31.0682 11.254 31.449 11.128 31.7907L6.60432 44.0505C6.44123 44.4925 6.01417 44.7861 5.53695 44.7842ZM8.45053 32.5243L2.7318 32.5243L5.55497 40.3718L8.45053 32.5243Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26.2091 20.2396V20.5806V22.1721V22.6268V26.4994V47.7495V54.4093V56.7272C26.9593 56.8921 27.6952 57.1435 28.4014 57.4815L28.494 57.5258V57.5752C31.1575 59.1084 32.9495 61.9739 32.9495 65.2559C32.9495 70.1529 28.9599 74.1227 24.0384 74.1227C19.1169 74.1227 15.1273 70.1529 15.1273 65.2559C15.1273 61.9272 16.9707 59.0269 19.6971 57.5106V57.4706C20.3965 57.138 21.1249 56.8901 21.8678 56.7269V54.4091V47.7495V26.4994V22.6268V22.1721V20.5806V20.2396C21.8678 19.0467 22.8396 18.0797 24.0384 18.0797C25.2372 18.0797 26.2091 19.0467 26.2091 20.2396ZM19.5829 55.0325V22.6268V20.5806V20.2396C19.5829 17.7911 21.5777 15.8062 24.0384 15.8062C26.4991 15.8062 28.494 17.7911 28.494 20.2396V20.5806V22.6268V55.0325C32.4604 56.7464 35.2344 60.6788 35.2344 65.2559C35.2344 71.4085 30.2218 76.3963 24.0384 76.3963C17.855 76.3963 12.8424 71.4085 12.8424 65.2559C12.8424 60.6788 15.6164 56.7464 19.5829 55.0325Z"
                      fill="#1E3540"
                    />
                  </svg>
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-black"
                >
                  Temperature Sensor
                </h3>
                <p class="text-gray-500 text-sm">
                  Temperature sensors address needs for monitoring of
                  temperature of Rooms where Flammable liquids &amp; gases are
                  stored, including cold chain storage. Seedlings and crops
                  temperature monitoring Other applications such as Chicken
                  Coops, Barns, Stables where your important assets are kept.
                </p>
              </div>
            </div>
            <div
              class="inline-block px-4 cursor-pointer"
              @click="$router.push('/product/gate-wireless-sensor')"
            >
              <div
                class="w-96 h-64 max-w-xs overflow-hidden rounded-lg shadow-md hover:shadow-xl bg-white transition-shadow duration-300 ease-in-out"
              >
                <div class="inline-block text-gray-900 mt-4">
                  <!-- icon -->
                  <svg
                    width="30"
                    height="50"
                    viewBox="0 0 67 84"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.4575 1.58008L2.06665 10.1503V75.0043L32.4575 82.4804V1.58008Z"
                      fill="#00E192"
                    />
                    <path
                      d="M50.3273 42.9724L49.4155 37.502L45.404 36.0433L41.0277 39.1431V44.2488L46.6804 46.2546L50.3273 42.9724Z"
                      fill="#00E192"
                    />
                    <path
                      d="M22.0328 78.4916L4.13123 8.95072L32.3607 1.37695V81.9342L22.0328 78.4916Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M34.3902 0.310376C34.7432 0.586881 34.9494 1.01037 34.9494 1.45877V82.5415C34.9494 82.9899 34.7432 83.4134 34.3902 83.6899C34.0372 83.9664 33.5767 84.0652 33.1413 83.9578L1.10939 76.0562C0.457838 75.8954 0 75.3109 0 74.6399V9.36038C0 8.68931 0.457838 8.1048 1.10939 7.94408L33.1413 0.0424685C33.5767 -0.064924 34.0372 0.0338711 34.3902 0.310376ZM2.91752 10.503V73.4972L32.0319 80.6791V3.3211L2.91752 10.503ZM44.0667 9.90742H37.7454V6.9899H45.5254C46.3311 6.9899 46.9842 7.64301 46.9842 8.44866V33.4299H44.0667V9.90742Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M44.0668 75.0046V49.2939H46.9843V75.0046H44.0668Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.4892 39.9336L28.2026 39.9336L28.2026 42.8511L22.4892 42.8511L22.4892 39.9336Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M53.936 37.1572C53.0984 35.8664 51.8968 34.853 50.4832 34.2451L51.6358 31.5649C53.5795 32.4008 55.2317 33.7942 56.3834 35.5691C57.5351 37.3439 58.1347 39.4204 58.1063 41.536C58.0779 43.6516 57.4227 45.7112 56.2237 47.4545C55.0248 49.1978 53.3358 50.5463 51.3703 51.3297L50.2902 48.6195C51.7196 48.0498 52.9479 47.069 53.8199 45.8012C54.6919 44.5334 55.1683 43.0354 55.189 41.4968C55.2097 39.9582 54.7736 38.448 53.936 37.1572Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M61.1716 34.9074C59.889 32.9309 58.0491 31.3791 55.8845 30.4483L57.0371 27.7681C59.7317 28.9269 62.0222 30.8587 63.619 33.3193C65.2157 35.7798 66.0469 38.6586 66.0075 41.5916C65.9681 44.5246 65.0599 47.38 63.3976 49.7968C61.7354 52.2136 59.3938 54.0832 56.6691 55.1692L55.5889 52.459C57.7776 51.5866 59.6586 50.0848 60.9938 48.1435C62.329 46.2021 63.0586 43.9084 63.0902 41.5524C63.1219 39.1964 62.4542 36.8839 61.1716 34.9074Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M45.7077 44.9179C47.6547 44.9179 49.2331 43.3395 49.2331 41.3926C49.2331 39.4456 47.6547 37.8672 45.7077 37.8672C43.7608 37.8672 42.1824 39.4456 42.1824 41.3926C42.1824 43.3395 43.7608 44.9179 45.7077 44.9179ZM45.7077 47.8354C49.266 47.8354 52.1506 44.9509 52.1506 41.3926C52.1506 37.8343 49.266 34.9497 45.7077 34.9497C42.1495 34.9497 39.2649 37.8343 39.2649 41.3926C39.2649 44.9509 42.1495 47.8354 45.7077 47.8354Z"
                      fill="#1E3540"
                    />
                  </svg>
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-black"
                >
                  Gate Sensor
                </h3>
                <p class="text-gray-500 text-sm">
                  Door sensors detect state of doors, gates or flaps in
                  industrial/utility or property and farm management
                  environment. Typical doors/remote gates to be monitored are
                  those where unauthorised entry could lead to accidents or
                  losses but also general entry to a property (gates) or fire
                  doors.
                </p>
              </div>
            </div>
            <div
              class="inline-block px-4 cursor-pointer"
              @click="$router.push('/product/water-wireless-sensor')"
            >
              <div
                class="w-96 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <div class="inline-block text-gray-900 mt-4">
                  <!-- icon -->
                  <svg
                    width="30"
                    height="50"
                    viewBox="0 0 68 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M55.7322 46.3454C60.4796 48.4524 65.0034 47.2233 66.6719 46.3454V77.899C66.6719 81.4933 65.6054 81.9788 65.0722 81.7722H18.0625L19.0945 46.3454C23.3775 50.2186 33.3368 46.3454 35.1429 45.3125C36.5877 44.4862 42.006 47.1372 44.5345 48.566C46.289 46.9479 50.9848 44.2384 55.7322 46.3454Z"
                      fill="#00E192"
                    />
                    <path
                      d="M32.0148 67.983C26.1386 70.2585 20.6004 68.9311 18.5658 67.983L17.5312 81.0156H61.6026H66.6719V66.9486C60.7233 71.0343 56.2748 67.983 54.0505 66.9486C51.8262 65.9143 48.7226 67.983 44.481 69.7931C40.2394 71.6031 39.36 65.1385 32.0148 67.983Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.4962 17.8898H4.16005C3.24104 17.8898 2.49603 18.631 2.49603 19.5454V78.7358C2.49603 79.6502 3.24104 80.3915 4.16005 80.3915H15.4962C16.4152 80.3915 17.1602 79.6502 17.1602 78.7358V19.5454C17.1602 18.631 16.4152 17.8898 15.4962 17.8898ZM4.16005 15.4062C1.86252 15.4062 0 17.2594 0 19.5454V78.7358C0 81.0218 1.86252 82.875 4.16005 82.875H15.4962C17.7937 82.875 19.6562 81.0218 19.6562 78.7358V19.5454C19.6562 17.2594 17.7937 15.4062 15.4962 15.4062H4.16005Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M68 37.7188V79.5684C68 81.3946 66.5122 82.875 64.6768 82.875H15.1406V80.395H64.6768C65.1357 80.395 65.5076 80.0249 65.5076 79.5684V37.7188H68Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.53125 26.5625C0.53125 25.6823 1.2405 24.9688 2.1154 24.9688H7.71273C8.58763 24.9688 9.29688 25.6823 9.29688 26.5625C9.29688 27.4427 8.58763 28.1562 7.71273 28.1562H2.1154C1.2405 28.1562 0.53125 27.4427 0.53125 26.5625Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.53125 35.5938C0.53125 34.7135 1.23205 34 2.09654 34H10.6535C11.5179 34 12.2188 34.7135 12.2188 35.5938C12.2188 36.474 11.5179 37.1875 10.6535 37.1875H2.09654C1.23205 37.1875 0.53125 36.474 0.53125 35.5938Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.53125 53.7891C0.53125 52.9822 1.23205 52.3281 2.09654 52.3281H10.6535C11.5179 52.3281 12.2188 52.9822 12.2188 53.7891C12.2188 54.5959 11.5179 55.25 10.6535 55.25H2.09654C1.23205 55.25 0.53125 54.5959 0.53125 53.7891Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.53125 71.9844C0.53125 71.1042 1.23205 70.3906 2.09654 70.3906H10.6535C11.5179 70.3906 12.2188 71.1042 12.2188 71.9844C12.2188 72.8646 11.5179 73.5781 10.6535 73.5781H2.09654C1.23205 73.5781 0.53125 72.8646 0.53125 71.9844Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.53125 44.625C0.53125 43.7448 1.2405 43.0312 2.1154 43.0312H7.71273C8.58763 43.0312 9.29688 43.7448 9.29688 44.625C9.29688 45.5052 8.58763 46.2188 7.71273 46.2188H2.1154C1.2405 46.2188 0.53125 45.5052 0.53125 44.625Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M0.53125 62.6875C0.53125 61.8073 1.2405 61.0938 2.1154 61.0938H7.71273C8.58763 61.0938 9.29688 61.8073 9.29688 62.6875C9.29688 63.5677 8.58763 64.2812 7.71273 64.2812H2.1154C1.2405 64.2812 0.53125 63.5677 0.53125 62.6875Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M68 46.0701L66.2794 47.4015C63.7926 49.3258 60.4663 49.7525 57.5781 48.5178L55.1992 47.5008C53.6184 46.825 51.8296 46.838 50.2587 47.5366L47.1707 48.9098C44.9714 49.8878 42.4671 49.9059 40.254 48.9599L36.8498 47.5045C35.2648 46.8269 33.4708 46.8418 31.8972 47.5457L28.8301 48.9175C26.6561 49.8899 24.1802 49.9236 21.9808 49.0106L17.7969 47.274L18.7445 44.9685L22.9284 46.7052C24.4994 47.3573 26.2679 47.3332 27.8208 46.6386L30.8879 45.2668C33.091 44.2814 35.6025 44.2606 37.8215 45.2092L41.2257 46.6645C42.8065 47.3403 44.5953 47.3274 46.1662 46.6288L49.2542 45.2555C51.4535 44.2775 53.9578 44.2594 56.1709 45.2055L58.5498 46.2225C60.6128 47.1044 62.9887 46.7996 64.765 45.4251L66.4856 44.0938L68 46.0701Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M68 55.1014L66.2794 56.4328C63.7926 58.357 60.4663 58.7838 57.5781 57.5491L55.1992 56.5321C53.6184 55.8563 51.8296 55.8692 50.2587 56.5678L47.1707 57.9411C44.9714 58.9191 42.4671 58.9372 40.254 57.9911L36.8498 56.5358C35.2648 55.8582 33.4708 55.8731 31.8972 56.5769L28.8301 57.9488C26.6561 58.9212 24.1802 58.9548 21.9808 58.0419L17.7969 56.3053L18.7445 53.9998L22.9284 55.7364C24.4994 56.3885 26.2679 56.3645 27.8208 55.6699L30.8879 54.2981C33.091 53.3127 35.6025 53.2918 37.8215 54.2404L41.2257 55.6958C42.8065 56.3715 44.5953 56.3586 46.1662 55.66L49.2542 54.2868C51.4535 53.3088 53.9578 53.2906 56.1709 54.2367L58.5498 55.2537C60.6128 56.1357 62.9887 55.8308 64.765 54.4564L66.4856 53.125L68 55.1014Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M68 67.3201L66.2794 68.6515C63.7926 70.5758 60.4663 71.0025 57.5781 69.7678L55.1992 68.7508C53.6184 68.075 51.8296 68.088 50.2587 68.7866L47.1707 70.1598C44.9714 71.1378 42.4671 71.1559 40.254 70.2099L36.8498 68.7545C35.2648 68.0769 33.4708 68.0918 31.8972 68.7957L28.8301 70.1675C26.6561 71.1399 24.1802 71.1736 21.9808 70.2606L17.7969 68.524L18.7445 66.2185L22.9284 67.9552C24.4994 68.6073 26.2679 68.5832 27.8208 67.8886L30.8879 66.5168C33.091 65.5314 35.6025 65.5106 37.8215 66.4592L41.2257 67.9145C42.8065 68.5903 44.5953 68.5774 46.1662 67.8788L49.2542 66.5055C51.4535 65.5275 53.9578 65.5094 56.1709 66.4555L58.5498 67.4725C60.6128 68.3544 62.9887 68.0496 64.765 66.6751L66.4856 65.3438L68 67.3201Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M59.7656 23.4632C59.7656 30.085 54.414 36.125 47.8125 36.125C41.211 36.125 35.8594 30.085 35.8594 23.4632C35.8594 16.8413 47.8125 0 47.8125 0C47.8125 0 59.7656 16.8413 59.7656 23.4632Z"
                      fill="#00E192"
                    />
                    <path
                      d="M53.0029 10.6033L47.5604 3.1875C50.5328 21.4003 44.717 30.5171 41.4375 32.7988C42.7458 33.4211 45.802 34.7384 47.5604 35.0288C49.3187 35.3192 53.282 33.6632 55.0438 32.7988L58.9688 25.2275L57.6604 20.0416L53.0029 10.6033Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M47.8125 33.6443C52.8778 33.6443 57.2926 28.8924 57.2926 23.4632C57.2926 22.378 56.751 20.5038 55.6409 18.0173C54.5752 15.6302 53.1342 12.9959 51.6637 10.5168C50.28 8.18399 48.893 6.02668 47.8125 4.39691C46.732 6.02668 45.345 8.18399 43.9613 10.5168C42.4908 12.9959 41.0498 15.6302 39.9841 18.0173C38.874 20.5038 38.3324 22.378 38.3324 23.4632C38.3324 28.8924 42.7472 33.6443 47.8125 33.6443ZM46.3153 2.18125C43.1072 6.95377 35.8594 18.3068 35.8594 23.4632C35.8594 30.085 41.211 36.125 47.8125 36.125C54.414 36.125 59.7656 30.085 59.7656 23.4632C59.7656 18.3068 52.5178 6.95377 49.3097 2.18125C48.3979 0.824856 47.8125 0 47.8125 0C47.8125 0 47.2271 0.824856 46.3153 2.18125Z"
                      fill="#1E3540"
                    />
                  </svg>
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-black"
                >
                  Water Level Sensor
                </h3>
                <p class="text-gray-500 text-sm">
                  Water level monitor will send notification at certain
                  pre-defined level (or optionally two levels) as easily
                  regulated by the user, notifications will alert you to
                  undertake the necessary action required to apply corrective
                  action.
                </p>
              </div>
            </div>
            <div
              class="inline-block px-3 cursor-pointer"
              @click="$router.push('/product/flood-wireless-sensor')"
            >
              <div
                class="w-96 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <div class="inline-block text-gray-900 mt-4">
                  <!-- icon -->
                  <svg
                    width="30"
                    height="50"
                    viewBox="0 0 99 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M69.4092 28.3953C69.9368 28.6678 80.2273 42.5302 81.9092 41.5004V65.4096C81.9092 69.6259 80.8342 70.1954 80.2966 69.9531H32.9092L33.9495 28.3953C38.9092 31.5 48.3062 28.3953 50.1268 27.1837C51.5833 26.2144 56.9092 29.5 59.4092 30.0004C63.4092 29 64.6236 25.9237 69.4092 28.3953Z"
                      fill="#00E192"
                    />
                    <path
                      d="M47.3927 48.3111C41.5165 50.5866 35.9783 49.2592 33.9437 48.3111L32.9092 74H76.9806H82.0498V47.2767C76.1012 51.3624 71.6527 48.3111 69.4284 47.2767C67.2042 46.2424 64.1005 48.3111 59.8589 50.1212C55.6173 51.9313 54.738 45.4666 47.3927 48.3111Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.4054 9.88976H19.0692C18.1502 9.88976 17.4052 10.631 17.4052 11.5454V70.7358C17.4052 71.6502 18.1502 72.3915 19.0692 72.3915H30.4054C31.3244 72.3915 32.0694 71.6502 32.0694 70.7358V11.5454C32.0694 10.631 31.3244 9.88976 30.4054 9.88976ZM19.0692 7.40625C16.7717 7.40625 14.9092 9.25943 14.9092 11.5454V70.7358C14.9092 73.0218 16.7717 74.875 19.0692 74.875H30.4054C32.7029 74.875 34.5654 73.0218 34.5654 70.7358V11.5454C34.5654 9.25943 32.7029 7.40625 30.4054 7.40625H19.0692Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M82.9092 41V72.5103C82.9092 73.8853 81.4174 75 79.5772 75H29.9092V73.1327H79.5772C80.0372 73.1327 80.4102 72.8541 80.4102 72.5103V41H82.9092Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M15.4404 18.5625C15.4404 17.6823 16.1497 16.9688 17.0246 16.9688H22.6219C23.4968 16.9688 24.2061 17.6823 24.2061 18.5625V18.5625C24.2061 19.4427 23.4968 20.1562 22.6219 20.1562H17.0246C16.1497 20.1562 15.4404 19.4427 15.4404 18.5625V18.5625Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M15.4404 27.5938C15.4404 26.7135 16.1412 26 17.0057 26H25.5626C26.4271 26 27.1279 26.7135 27.1279 27.5938V27.5938C27.1279 28.474 26.4271 29.1875 25.5626 29.1875H17.0057C16.1412 29.1875 15.4404 28.474 15.4404 27.5938V27.5938Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M15.4404 45.7891C15.4404 44.9822 16.1412 44.3281 17.0057 44.3281H25.5626C26.4271 44.3281 27.1279 44.9822 27.1279 45.7891V45.7891C27.1279 46.5959 26.4271 47.25 25.5626 47.25H17.0057C16.1412 47.25 15.4404 46.5959 15.4404 45.7891V45.7891Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M15.4404 63.9844C15.4404 63.1042 16.1412 62.3906 17.0057 62.3906H25.5626C26.4271 62.3906 27.1279 63.1042 27.1279 63.9844V63.9844C27.1279 64.8646 26.4271 65.5781 25.5626 65.5781H17.0057C16.1412 65.5781 15.4404 64.8646 15.4404 63.9844V63.9844Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M15.4404 36.625C15.4404 35.7448 16.1497 35.0312 17.0246 35.0312H22.6219C23.4968 35.0312 24.2061 35.7448 24.2061 36.625V36.625C24.2061 37.5052 23.4968 38.2188 22.6219 38.2188H17.0246C16.1497 38.2188 15.4404 37.5052 15.4404 36.625V36.625Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M15.4404 54.6875C15.4404 53.8073 16.1497 53.0938 17.0246 53.0938H22.6219C23.4968 53.0938 24.2061 53.8073 24.2061 54.6875V54.6875C24.2061 55.5677 23.4968 56.2812 22.6219 56.2812H17.0246C16.1497 56.2812 15.4404 55.5677 15.4404 54.6875V54.6875Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M79.8773 38.4913L80.9092 39C78.4223 40.9243 75.5786 41.2438 72.6904 40.0091L70.3115 38.9921C68.7307 38.3163 66.9419 38.3292 65.371 39.0278L62.283 40.4011C60.0837 41.3791 57.5794 41.3972 55.3663 40.4511L51.9621 38.9958C50.3771 38.3182 48.5831 38.3331 47.0095 39.037L43.9424 40.4088C41.7684 41.3812 39.2925 41.4148 37.0931 40.5019L32.9092 38.7653L33.8568 36.4598L38.0407 38.1964C39.6117 38.8485 41.3802 38.8245 42.9331 38.1299L46.0002 36.7581C48.2033 35.7727 50.7148 35.7518 52.9338 36.7005L56.338 38.1558C57.9188 38.8316 59.7076 38.8186 61.2785 38.12L64.3665 36.7468C66.5658 35.7688 69.0701 35.7506 71.2832 36.6967L73.6621 37.7137C75.7251 38.5957 77.1329 38.3657 78.9092 36.9912V38.4912L79.8773 38.4913Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M81.9092 47.1259L80.2298 48.5579C77.8026 50.6277 74.556 51.0867 71.737 49.7587L69.4151 48.6647C67.8723 47.9379 66.1263 47.9518 64.593 48.7032L61.5791 50.1803C59.4325 51.2323 56.9882 51.2518 54.8281 50.2341L51.5055 48.6687C49.9585 47.9399 48.2075 47.9559 46.6716 48.713L43.678 50.1886C41.5561 51.2345 39.1396 51.2707 36.9928 50.2888L32.9092 48.4208L33.8341 45.941L37.9177 47.8089C39.4511 48.5103 41.1772 48.4845 42.6929 47.7374L45.6864 46.2618C47.8367 45.2019 50.2881 45.1794 52.4539 46.1998L55.7766 47.7652C57.3194 48.4921 59.0654 48.4782 60.5986 47.7267L63.6126 46.2496C65.7592 45.1977 68.2035 45.1782 70.3635 46.1958L72.6855 47.2897C74.699 48.2383 77.018 47.9105 78.7517 46.4321L80.4311 45L81.9092 47.1259Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M82.9092 59.1259L81.1955 60.5579C78.7188 62.6277 75.4059 63.0867 72.5295 61.7587L70.1602 60.6647C68.5858 59.9379 66.8042 59.9518 65.2397 60.7032L62.1642 62.1803C59.9738 63.2323 57.4796 63.2518 55.2755 62.2341L51.885 60.6687C50.3064 59.9399 48.5197 59.9559 46.9525 60.713L43.8978 62.1886C41.7325 63.2345 39.2667 63.2707 37.0762 62.2888L32.9092 60.4208L33.853 57.941L38.0199 59.8089C39.5846 60.5103 41.3459 60.4845 42.8925 59.7374L45.9472 58.2618C48.1414 57.2019 50.6428 57.1794 52.8528 58.1998L56.2432 59.7652C57.8176 60.4921 59.5992 60.4782 61.1637 59.7267L64.2392 58.2496C66.4296 57.1977 68.9238 57.1782 71.1279 58.1958L73.4972 59.2897C75.5518 60.2383 77.9182 59.9105 79.6873 58.4321L81.4009 57L82.9092 59.1259Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.933115 55.3091C0.418049 54.77 0.437512 53.9154 0.976588 53.4004L14.9766 40.0239C15.5157 39.5089 16.3702 39.5283 16.8853 40.0674C17.4003 40.6065 17.3809 41.461 16.8418 41.9761L2.84181 55.3525C2.30273 55.8676 1.44818 55.8482 0.933115 55.3091ZM32.4085 24.4061C31.908 23.8535 31.9504 22.9997 32.5031 22.4993L48.3792 8.12363C48.9126 7.64063 49.7311 7.66092 50.2399 8.16975L97.6161 55.5459C98.1433 56.0731 98.1433 56.9279 97.6161 57.4551C97.0889 57.9823 96.2341 57.9823 95.7069 57.4551L49.2392 10.9874L34.3153 24.5007C33.7627 25.0012 32.9089 24.9588 32.4085 24.4061Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M65.4794 26.2238C65.2226 26.3432 64.8773 26.5159 64.3665 26.7431L61.2785 28.1163C59.7076 28.8149 57.9188 28.8278 56.338 28.1521L52.9338 26.6967C50.7148 25.7481 48.2033 25.769 46.0002 26.7544L42.9331 28.1262C41.3802 28.8208 39.6117 28.8448 38.0407 28.1927L33.8568 26.4561L32.9092 28.7616L37.0931 30.4982C39.2925 31.4111 41.7684 31.3775 43.9424 30.4051L47.0095 29.0332C48.5831 28.3294 50.3771 28.3145 51.9621 28.9921L55.3663 30.4474C57.5794 31.3935 60.0837 31.3754 62.283 30.3973L65.371 29.0241C65.4233 29.0008 65.4749 28.9779 65.5257 28.9552C66.2898 28.6146 66.8939 28.3453 67.5109 28.2554L65.4794 26.2238Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.5596 16.9999C56.5596 13.4929 59.4026 10.6499 62.9096 10.6499C66.4166 10.6499 69.2596 13.4929 69.2596 16.9999V27.9999H66.5596V16.9999C66.5596 14.9841 64.9254 13.3499 62.9096 13.3499C60.8937 13.3499 59.2596 14.9841 59.2596 16.9999V17.4999H56.5596V16.9999Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M71.259 24.6961C71.452 23.9759 72.1922 23.5485 72.9124 23.7415L78.0318 25.1132C78.752 25.3062 79.1794 26.0465 78.9864 26.7666C78.7934 27.4868 78.0532 27.9142 77.333 27.7212L72.2136 26.3495C71.4934 26.1565 71.066 25.4163 71.259 24.6961Z"
                      fill="#8B0000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M70.9092 19.6498C70.9092 18.9042 71.5136 18.2998 72.2592 18.2998H77.5592C78.3048 18.2998 78.9092 18.9042 78.9092 19.6498C78.9092 20.3954 78.3048 20.9998 77.5592 20.9998H72.2592C71.5136 20.9998 70.9092 20.3954 70.9092 19.6498Z"
                      fill="#8B0000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M70.6961 13.7664C70.5031 13.0462 70.9305 12.306 71.6507 12.113L76.7701 10.7413C77.4903 10.5483 78.2305 10.9757 78.4235 11.6958C78.6165 12.416 78.1891 13.1563 77.4689 13.3493L72.3495 14.721C71.6293 14.914 70.8891 14.4866 70.6961 13.7664Z"
                      fill="#8B0000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M66.9546 9.70215C66.4274 9.17494 66.4274 8.32017 66.9546 7.79296L70.7023 4.0453C71.2295 3.51809 72.0842 3.51809 72.6114 4.0453C73.1387 4.57251 73.1387 5.42728 72.6114 5.95449L68.8638 9.70215C68.3366 10.2294 67.4818 10.2294 66.9546 9.70215Z"
                      fill="#8B0000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M58.521 9.70215C59.0482 9.17494 59.0482 8.32017 58.521 7.79296L54.7733 4.0453C54.2461 3.51809 53.3913 3.51809 52.8641 4.0453C52.3369 4.57251 52.3369 5.42728 52.8641 5.95449L56.6118 9.70215C57.139 10.2294 57.9938 10.2294 58.521 9.70215Z"
                      fill="#8B0000"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M62.559 8C61.8134 8 61.209 7.39558 61.209 6.65L61.209 1.35C61.209 0.604415 61.8134 -2.64198e-08 62.559 -5.90104e-08C63.3046 -9.16009e-08 63.909 0.604415 63.909 1.35L63.909 6.65C63.909 7.39558 63.3046 8 62.559 8Z"
                      fill="#8B0000"
                    />
                  </svg>
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-black"
                >
                  Flood Wireless Sensor
                </h3>
                <p class="text-gray-500 text-sm ">
                  Tired of your critical assets being flooded and/or destroyed.
                  Our Industrial Flood Monitor will send Alert Notification when
                  flooding occurs giving you the time and response required to
                  take the necessary action.
                </p>
              </div>
            </div>
            <div
              class="inline-block px-3 cursor-pointer"
              @click="$router.push('/product/humidity-wireless-sensor')"
            >
              <div
                class="w-96 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <div class="inline-block text-gray-900 mt-4">
                  <!-- icon -->
                  <svg
                    width="30"
                    height="50"
                    viewBox="0 0 66 74"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52.8513 49.2596C52.8513 52.7652 52.0596 56.2233 50.5381 59.3638C49.0166 62.5044 46.8064 65.2423 44.0802 67.3636C41.3541 69.485 38.1858 70.9324 34.8228 71.5927C31.4598 72.2531 27.9932 72.1085 24.6938 71.1703C21.3944 70.2321 18.3516 68.5257 15.803 66.1844C13.2544 63.843 11.2691 60.9302 10.0021 57.6734C8.73518 54.4165 8.22086 50.9039 8.49938 47.4099C8.7779 43.9159 9.84172 40.5352 11.6077 37.5317"
                      stroke="#1E3540"
                      stroke-width="2.7"
                      stroke-linecap="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.2771 34.7634V7.71557C36.2771 4.60346 33.7461 2.0806 30.6238 2.0806C27.5016 2.0806 24.9705 4.60346 24.9705 7.71556V34.7634V37.6265V37.6624V39.8215V39.9523C21.3403 41.9541 18.8823 45.8099 18.8823 50.2379C18.8823 56.7254 24.1586 61.9846 30.6673 61.9846C37.176 61.9846 42.4523 56.7254 42.4523 50.2379C42.4523 45.7746 39.955 41.8927 36.2771 39.9048V39.8406V37.6865V37.5878V34.7634ZM38.3645 38.7325V7.71557C38.3645 3.45438 34.8989 0 30.6238 0C26.3487 0 22.8831 3.45438 22.8831 7.71556V38.7909C19.2088 41.2782 16.7949 45.4771 16.7949 50.2379C16.7949 57.8745 23.0058 64.0652 30.6673 64.0652C38.3288 64.0652 44.5397 57.8745 44.5397 50.2379C44.5397 45.4396 42.0877 41.2122 38.3645 38.7325Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M28.5399 15.1885V42.8685C26.8108 43.473 23.3525 45.7185 23.3525 49.8641C23.3525 55.046 28.5399 57.5074 31.3266 57.5074C34.1132 57.5074 38.4861 55.0891 38.4861 49.8641C38.4861 45.684 35.0278 43.4586 33.2986 42.8685V15.1885C33.2986 13.9362 32.1411 13.1157 30.6406 13.1157C29.4402 13.1157 28.74 14.4976 28.5399 15.1885Z"
                      fill="#00E192"
                    />
                    <path
                      d="M30.9199 45.6526C35.3591 50.8988 31.4244 55.7416 28.9021 57.5071H33.4422L36.9733 53.7238L38.7389 49.9404L37.2256 45.9048L33.19 42.6259V33.5457V20.6823V14.6289L31.1722 12.8633C29.7429 13.3677 27.2879 14.3766 28.9021 14.3766C30.5164 14.3766 30.9199 15.7218 30.9199 16.3944V45.6526Z"
                      fill="#009F67"
                    />
                    <path
                      d="M46.0395 9.08008C46.604 9.08008 47.0615 9.53178 47.0615 10.089V10.089C47.0615 10.6462 46.604 11.0979 46.0395 11.0979L41.5257 11.0979C40.9612 11.0979 40.5037 10.6462 40.5037 10.089V10.089C40.5037 9.53178 40.9612 9.08008 41.5257 9.08008L46.0395 9.08008Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M46.0395 16.8511C46.604 16.8511 47.0615 17.3028 47.0615 17.86V17.86C47.0615 18.4172 46.604 18.8689 46.0395 18.8689L41.5257 18.8689C40.9612 18.8689 40.5037 18.4172 40.5037 17.86V17.86C40.5037 17.3028 40.9612 16.8511 41.5257 16.8511L46.0395 16.8511Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M46.0395 32.1704C46.604 32.1704 47.0615 32.6221 47.0615 33.1793V33.1793C47.0615 33.7365 46.604 34.1882 46.0395 34.1882L41.5257 34.1882C40.9612 34.1882 40.5037 33.7365 40.5037 33.1793V33.1793C40.5037 32.6221 40.9612 32.1704 41.5257 32.1704L46.0395 32.1704Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M46.0395 24.5107C46.604 24.5107 47.0615 24.9624 47.0615 25.5196V25.5196C47.0615 26.0768 46.604 26.5285 46.0395 26.5285L41.5257 26.5285C40.9612 26.5285 40.5037 26.0768 40.5037 25.5196V25.5196C40.5037 24.9624 40.9612 24.5107 41.5257 24.5107L46.0395 24.5107Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M12.7598 18.6646H19.8221L16.3356 9.08008L12.7598 18.6646Z"
                      fill="#00E192"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.5895 8.0713C16.955 8.07275 17.2803 8.30017 17.4025 8.6397L20.7807 18.0302C20.8748 18.2916 20.8341 18.5816 20.6718 18.8081C20.5095 19.0345 20.2456 19.1692 19.9643 19.1692H13.1211C12.8385 19.1692 12.5737 19.0334 12.4116 18.8053C12.2495 18.5772 12.2105 18.2855 12.307 18.0238L15.7719 8.63323C15.8969 8.29468 16.224 8.06985 16.5895 8.0713ZM14.3578 17.4618H18.7381L16.5757 11.451L14.3578 17.4618Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M19.8213 24.2138L12.759 24.2138L16.2454 33.7983L19.8213 24.2138Z"
                      fill="#00E192"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.4964 34.3027C16.1309 34.3013 15.8056 34.0739 15.6835 33.7343L12.3052 24.3438C12.2112 24.0825 12.2518 23.7924 12.4141 23.5659C12.5765 23.3395 12.8403 23.2048 13.1216 23.2048L19.9648 23.2048C20.2474 23.2048 20.5122 23.3407 20.6743 23.5688C20.8364 23.7969 20.8755 24.0885 20.7789 24.3503L17.314 33.7408C17.1891 34.0793 16.862 34.3042 16.4964 34.3027ZM18.7281 24.9122L14.3478 24.9122L16.5102 30.923L18.7281 24.9122Z"
                      fill="#1E3540"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M32.3301 15.5027V15.764V16.983V17.3313V20.2975V36.5742V41.6753V43.4507C32.9047 43.577 33.4684 43.7696 34.0093 44.0285L34.0802 44.0624V44.1003C36.1204 45.2746 37.493 47.4694 37.493 49.9833C37.493 53.7342 34.4371 56.7749 30.6675 56.7749C26.8978 56.7749 23.8419 53.7342 23.8419 49.9833C23.8419 47.4337 25.2539 45.2122 27.3422 44.0508V44.0201C27.8779 43.7654 28.4359 43.5755 29.0048 43.4505V41.6751V36.5742V20.2975V17.3313V16.983V15.764V15.5027C29.0048 14.5891 29.7492 13.8484 30.6675 13.8484C31.5857 13.8484 32.3301 14.5891 32.3301 15.5027ZM27.2547 42.1527V17.3313V15.764V15.5027C27.2547 13.6273 28.7826 12.1069 30.6675 12.1069C32.5523 12.1069 34.0802 13.6273 34.0802 15.5027V15.764V17.3313V42.1527C37.1183 43.4654 39.2431 46.4775 39.2431 49.9833C39.2431 54.696 35.4037 58.5164 30.6675 58.5164C25.9312 58.5164 22.0918 54.696 22.0918 49.9833C22.0918 46.4775 24.2166 43.4654 27.2547 42.1527Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M65.0348 51.6739C65.0348 56.746 60.9357 61.3724 55.8792 61.3724C50.8227 61.3724 46.7236 56.746 46.7236 51.6739C46.7236 46.6018 55.8792 33.7021 55.8792 33.7021C55.8792 33.7021 65.0348 46.6018 65.0348 51.6739Z"
                      fill="#00E192"
                    />
                    <path
                      d="M59.8547 41.8237L55.6859 36.1436C57.9627 50.0938 53.508 57.0768 50.9961 58.8246C51.9982 59.3012 54.3391 60.3102 55.6859 60.5326C57.0328 60.755 60.0685 59.4866 61.418 58.8246L64.4243 53.0252L63.4222 49.0531L59.8547 41.8237Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M55.8792 59.4723C59.759 59.4723 63.1405 55.8325 63.1405 51.6739C63.1405 50.8428 62.7257 49.4072 61.8754 47.5026C61.0591 45.6743 59.9554 43.6565 58.8291 41.7576C57.7693 39.9707 56.7069 38.3183 55.8792 37.07C55.0516 38.3183 53.9892 39.9707 52.9293 41.7576C51.803 43.6565 50.6993 45.6743 49.883 47.5026C49.0327 49.4072 48.6179 50.8428 48.6179 51.6739C48.6179 55.8325 51.9994 59.4723 55.8792 59.4723ZM54.7324 35.3729C52.2752 39.0284 46.7236 47.7244 46.7236 51.6739C46.7236 56.746 50.8227 61.3724 55.8792 61.3724C60.9357 61.3724 65.0348 56.746 65.0348 51.6739C65.0348 47.7244 59.4832 39.0284 57.026 35.3729C56.3276 34.334 55.8792 33.7021 55.8792 33.7021C55.8792 33.7021 55.4308 34.334 54.7324 35.3729Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M13.7872 40.7383C13.7872 44.6696 10.7009 48.2554 6.89362 48.2554C3.08638 48.2554 0 44.6696 0 40.7383C0 36.807 6.89362 26.8086 6.89362 26.8086C6.89362 26.8086 13.7872 36.807 13.7872 40.7383Z"
                      fill="#00E192"
                    />
                    <path
                      d="M10.1377 32.7954L6.80867 28.3403C8.62684 39.2817 5.06945 44.7586 3.06348 46.1294C3.86373 46.5032 5.73312 47.2945 6.80867 47.469C7.88421 47.6435 10.3084 46.6486 11.3861 46.1294L13.7869 41.5809L12.9866 38.4654L10.1377 32.7954Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.89362 46.7827C9.81487 46.7827 12.361 43.9615 12.361 40.7383C12.361 40.0941 12.0486 38.9814 11.4084 37.5052C10.7938 36.088 9.96277 34.5241 9.1147 33.0522C8.31671 31.6673 7.51679 30.3865 6.89362 29.419C6.27045 30.3865 5.47053 31.6673 4.67253 33.0522C3.82447 34.5241 2.99344 36.088 2.37883 37.5052C1.73862 38.9814 1.42627 40.0941 1.42627 40.7383C1.42627 43.9615 3.97236 46.7827 6.89362 46.7827ZM6.03015 28.1036C4.18 30.9369 0 37.677 0 40.7383C0 44.6696 3.08638 48.2554 6.89362 48.2554C10.7009 48.2554 13.7872 44.6696 13.7872 40.7383C13.7872 37.677 9.60724 30.9369 7.75708 28.1036C7.23125 27.2983 6.89362 26.8086 6.89362 26.8086C6.89362 26.8086 6.55598 27.2983 6.03015 28.1036Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M58.1029 26.8009C58.1029 29.6089 55.8984 32.1702 53.1789 32.1702C50.4594 32.1702 48.2549 29.6089 48.2549 26.8009C48.2549 23.9928 53.1789 16.8511 53.1789 16.8511C53.1789 16.8511 58.1029 23.9928 58.1029 26.8009Z"
                      fill="#00E192"
                    />
                    <path
                      d="M55.4964 21.1275L53.1185 17.9453C54.4172 25.7606 51.8762 29.6726 50.4434 30.6518C51.015 30.9188 52.3503 31.484 53.1185 31.6086C53.8867 31.7333 55.6183 31.0227 56.3881 30.6518L58.1029 27.4028L57.5313 25.1775L55.4964 21.1275Z"
                      fill="#009F67"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M53.1789 31.1183C55.2655 31.1183 57.0841 29.1032 57.0841 26.8009C57.0841 26.3407 56.861 25.5459 56.4037 24.4915C55.9647 23.4792 55.3711 22.3621 54.7654 21.3108C54.1954 20.3216 53.624 19.4067 53.1789 18.7156C52.7338 19.4067 52.1624 20.3216 51.5924 21.3108C50.9866 22.3621 50.3931 23.4792 49.954 24.4915C49.4968 25.5459 49.2736 26.3407 49.2736 26.8009C49.2736 29.1032 51.0923 31.1183 53.1789 31.1183ZM52.5621 17.7761C51.2406 19.7999 48.2549 24.6142 48.2549 26.8009C48.2549 29.6089 50.4594 32.1702 53.1789 32.1702C55.8984 32.1702 58.1029 29.6089 58.1029 26.8009C58.1029 24.6142 55.1172 19.7999 53.7957 17.7761C53.4201 17.2009 53.1789 16.8511 53.1789 16.8511C53.1789 16.8511 52.9377 17.2009 52.5621 17.7761Z"
                      fill="#1E3540"
                    />
                    <path
                      d="M58.3391 53.4971C57.9045 53.4971 57.6164 53.0723 57.6164 52.4229C57.6164 51.7734 57.8996 51.3535 58.3391 51.3535C58.7785 51.3535 59.0568 51.7637 59.0568 52.4229C59.0568 53.0674 58.7688 53.4971 58.3391 53.4971ZM58.3391 54.1221C59.218 54.1221 59.8381 53.4189 59.8381 52.4229C59.8381 51.4268 59.218 50.7285 58.3391 50.7285C57.4602 50.7285 56.8352 51.4316 56.8352 52.4229C56.8352 53.4189 57.4602 54.1221 58.3391 54.1221ZM54.2619 50.2256C55.1408 50.2256 55.761 49.5225 55.761 48.5264C55.761 47.5303 55.1408 46.832 54.2619 46.832C53.383 46.832 52.758 47.5352 52.758 48.5264C52.758 49.5225 53.383 50.2256 54.2619 50.2256ZM54.1838 54L56.6008 50.6943L59.3352 46.9541H58.4465L55.9758 50.3281L53.2854 54H54.1838ZM54.2619 49.6006C53.8274 49.6006 53.5393 49.1758 53.5393 48.5264C53.5393 47.877 53.8225 47.457 54.2619 47.457C54.7014 47.457 54.9797 47.8672 54.9797 48.5264C54.9797 49.1709 54.6916 49.6006 54.2619 49.6006Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <h3
                  class="text-lg leading-normal mb-2 font-semibold text-black"
                >
                  Temp/Humidity Wireless Sensor
                </h3>
                <p class="text-gray-500 text-sm">
                  Temperature and humidity sensors with external probe are used
                  in many industries including agriculture for soil conditions
                  measurements (optimisation of crop production) or for grain
                  conditions monitoring during storage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End heading -->
    </div>
  </div>
</template>

<style>
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
</style>
