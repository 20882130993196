<template>
  <div class="container mx-auto">
    <div class="relative">
      <h4 class="w-full text-3xl font-bold text-center sm:text-2xl md:text-2xl">
        What are IoTa Sensors
      </h4>
      <p class="mt-5 text-lg text-gray-700 text md:text-left">
        IOTA Sensors are Smart Wireless devices which
        work off the Sigfox Network , improving your farm management and
        agricultural efficiencies, by sending you critical alerts from
        your Farm assets, when you need it most..These Devices are easy to
        install and set up with replaceable internal AAA batteries which can
        last for 5 years.
      </p>
      <div class="flex items-center ml-9">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span
          class="mx-4 text-gray-700 dark:text-gray-300 text text-left"
        >
          No External Power required</span
        >
      </div>
       <div class="flex items-center ml-9">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span
          class="mx-4 text-gray-700 dark:text-gray-300 text text-left"
        >
          No Solar Power required.</span
        >
      </div>
       <div class="flex items-center ml-9">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span
          class="mx-4 text-gray-700 dark:text-gray-300 text text-left"
        >
          No WiFi required.</span
        >
      </div>
    </div>
    <div class="flex flex-col mt-12 animated fadeIn sm:flex-row">
      <div
        class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16"
      >
        <h4 class="mt-2 text-2xl sm:text-left md:text-2xl font-bold">
          A Wireless Tank Platform developed to give you “Peace of Mind”
        </h4>
        <a class="mt-5 text-lg text-gray-700 text md:text-left">
          Our small team in collaboration with stakeholders in the agricultural
          sector, developed a Smart wireless application for placement around
          agricultural infrastructure where limited alternatives, other than a
          physical inspection, were the only way to know if your Tanks & Gates
          are secure.
        </a>
        <a class="mt-5 text-lg text-gray-700 text md:text-left">
          With our specialized IOTA4U software combined with Devices which can
          withstand years of harsh environmental factors ensure your assets are
          Safe and Sound, giving you Peace of Mind.
        </a>
        <a class="mt-5 text-lg text-gray-700 text md:text-left">
          By utilising our Iota4u system for farming and agriculture, farmers
          can cut down on the number of tanker runs, temperature checks and gate
          security they need by at least 90%. This represents savings of
          thousands of dollars and many, many man-hours saved.
        </a>
      </div>
      <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
        <img
          class="sm:w-[32rem] flex-shrink-0 object-cover"
          src="@/assets/images/homefeature.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
