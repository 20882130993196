<script>
import counter from "vue3-autocounter";
export default {
  components: {
    counter,
  },
  data: () => {
    return {
      sensorsSales: "Sensors Sales",
      farmersWithiota4u: "Farmers With IOTA4U",
      appDownloads: "App downloads",
    };
  },
};
</script>

<template>
  <div class="container m-auto px-6 text-gray-500 md:px-12 xl:px-0 mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm">
    <div class="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
      <div class="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
        <counter
          ref="counter"
          :startAmount="0"
          :endAmount="100"
          :duration="4"
          :autoinit="true"
          @finished="alert(`Counting finished!`)"
          class="font-general-medium text-4xl font-bold font-bold text-green-500 text-secondary-dark dark:text-secondary-light mb-2"
          aria-label="About Status Counter"
        />
        <span class="block text-md font-general-semibold text-ternary-dark dark:text-ternary-light">
          {{ sensorsSales }}
        </span>
      </div>

      <div class="bg-white rounded-2xl  shadow-xl px-8 py-12 sm:px-12 lg:px-8">
        <counter
          ref="counter"
          :startAmount="0"
          :endAmount="50"
          :duration="4"
          :autoinit="true"
          @finished="alert(`Counting finished!`)"
          class="font-general-medium text-4xl font-bold text-green-500 font-semibold text-secondary-dark dark:text-secondary-light mb-2"
        />
        <span class="block text-md text-ternary-dark font-general-semibold dark:text-ternary-light">{{
          farmersWithiota4u
        }}</span>
      </div>

      <div class="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
        <counter
          ref="counter"
          :startAmount="0"
          :endAmount="92"
          :duration="4"
          :autoinit="true"
          @finished="alert(`Counting finished!`)"
          class="font-general-medium text-4xl font-bold font-bold text-green-500 text-secondary-dark dark:text-secondary-light mb-2"
        />
        <span class="block text-md text-ternary-dark font-general-semibold dark:text-ternary-light">
          {{ appDownloads }}
        </span>
      </div>
    </div>
  </div>
</template>
